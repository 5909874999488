@tailwind base;
@tailwind components;
@tailwind utilities;


.nav-link{
    @apply block text-d17-web-50 text-lg font-bold py-2.5 px-3.5;
}

.nav-link-principal{
    @apply block text-d17-dark bg-d17-web-50 rounded-xl text-lg font-bold p-2.5;
}

.services-card-title{
    @apply text-d17-white text-3xl font-bold text-center;
}

.section-title{
    @apply text-d17-dark text-4xl font-bold text-center my-6;
}

.card-link{
    @apply text-d17-white block p-2.5 border border-d17-white rounded-xl text-center cursor-pointer;
}

.route-section-link{
    @apply block p-2.5 bg-d17-dark text-d17-white text-center w-44 rounded-xl cursor-pointer;
}

em{
    @apply font-bold not-italic;
}


input[name="slider-1"]{
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 8px;
    padding: 2px;
    box-sizing: border-box;
    border: 1px solid #212B33;
    cursor: pointer;
}

input[name="slider-1"]:checked{
    background-color: #212B33;
}

.slider-radio{
    @apply appearance-none w-4 h-4 rounded-full mx-2;

}
.slider-radio:checked{
    opacity: 1;
}

.slider-navigator{
    left: calc(50% - 64px);

}

.animation-corporate span:nth-child(1){
    animation: 750ms ease-out 0ms forwards ltr-entrance;
    opacity: 0;
    display: block;
}

.animation-corporate span:nth-child(2){
    animation: 750ms ease-out 200ms forwards rtl-entrance;
    opacity: 0;
    display: block;
}

.animation-corporate span:nth-child(3){
    animation: 750ms ease-out 400ms forwards ltr-entrance;
    opacity: 0;
    display: block;
}

@keyframes ltr-entrance {
    0%{
        transform: translate(-200px);
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes rtl-entrance {
    0%{
        transform: translate(200px);
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translate(0);
    }
}

.phrases > span{
    display: block;
    opacity: 0;
}

#slide-container{
    transition: All .5s;
}

.animation-right div:nth-child(1){
    width: 321px;
}

.animation-right div:nth-child(2){
    width: 100px;
    right: 0;
    display: block;
}

.why{
    width: 32px;
    height: 32px;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #212B33;
    margin-right: 10px;
}
.why:nth-child(2){
    margin-right: 0;
}

.why:checked{
    background-color: #212B33;
}

/* POST STYLES */
.blog-post-img{
    height: 300px;
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 10;
}

.blog-post-title{
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog-post em{
    display: block;
}

.blog-post br{
    display: none;
}

.blog-post p{
    margin-bottom: 10px;
}

.blog-post{
    margin: 20px;
}

.blog-post p:last-child{
    text-align: center;
    font-size: 30px;
    margin-top: 20px;
}

.blog-post img{
    margin: 0 auto;
    object-fit: cover;
}

.blog-post iframe{
    margin: 30px auto!important;
}

.mkt-check:checked{
    background-color: #EC6A06;
}

.dg-check:checked{
    background-color: #15B097;
}

.cnt-check:checked{
    background-color: #006989;
}

.web-check:checked{
    background-color: #BEEE62;
}

.submit-btn{
    background-color: #BEEE62;
}

.line-clamp{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    overflow: hidden;
}

.width-post{
    max-width: 960px;
    margin: 0 auto;
    width: 95%;
}

@media (max-width: 1024px){
    #main-menu{
        position: absolute;
        top: 73px;
        width: 100%;
        left: 0;
        padding: 20px;
        background-color: #212B33;
        z-index: 100;
    }
}

